* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat';
}
.link, .job, .skills h4,
 .project, .email h1, .email h2 {
  font-weight: 400;
}
.link, .main-display, .skills h4,
 .about, .btn, .footer, 
 .icon, .arrow-up, .arrow {
  color: #ffffff;
}

.content, .btn, .link, .job {
  font-size: 1.5rem;
}
.btn, .icon, .arrow-up {
  cursor: pointer;
}
a{
  text-decoration: none;
  color: #000000;
}
.image {
    height: 100vh;
    background-image: url('./assets/background.jpg');
    background-size: cover;
}
/* Navbar */
.navbar {
    position: sticky; 
    top: 3%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.link {
    text-decoration: none;
}
/* Main Display */
.main-display {
    display: flex;
    justify-content: space-around;
    position: relative;
    top: 20%;
    padding: 0 1rem;
}
.name {
  font-size: 3.2rem;
  font-weight: 600;
}
/* Skills */
.skills {
  display: flex;
  flex-wrap: wrap;
  max-width: 425px;
  padding-top: 3.5rem;
}
.skills h4  {
  padding: 0.6rem 0.7rem;
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;
  border: 1px solid;
  border-radius: 20px;
}
.skills h4:hover {
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
  padding: 0.5rem;
  font-weight: 600;
}
/* About */
.about-descrip {
  max-width: 500px;
  line-height: 1.9;
}
/* Projects */
.project {
  font-size: 3.5rem;
  text-align: center;
  padding-top: 3rem;
}
.projects {
    width: 100%;
    max-width: 1500px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    grid-row-gap: 3rem;
    padding: 5rem;
    background-color: #ffffff;
}
.content{
  display: flex;
}
.card {
    margin: 0 auto;
    width: 500px;
    /* perspective: 500px; */
    padding: 1rem;
  }
  .card:hover{
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
    border-radius: 10px;
    padding: 1rem;
  }
  .card-title {
    padding-left: 1rem;
    color: #000000;
    font-size: 1.1rem;
  }
  .card-text {
    max-width: 340px;
    padding: 0.3rem 1rem;
    font-size: 0.9rem;
  }
  .card-img-top{
    height: 125px;
    width: 150px;
  }
  .project-skills{
    padding: 1rem 0 0 1rem;
    display: flex;
  }
  .project-skills div{
    max-width: fit-content;
    font-size: 0.9rem;
    border: 1px solid gray;
    border-radius: 20px;
    padding: 5px;
    margin-left: 0.5rem;
  }
  /* Footer */
  .contact {
    background-color: #000207;
  }
.footer {
    display: flex;
    justify-content: space-evenly;
    padding: 5rem 0;
}
.icon {
    font-size: 3.5rem;
    padding: .5rem 0;
}
.arrow {
  font-size: 2.5rem;
}
/* back-to-top */
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 10px;
  background: #000207;
  cursor: pointer;
  border-radius: 100%;
  font-size: 2.7rem;
  border: none;
  box-shadow: 0 5px 10px #ccc;
}
.back-to-top:hover {
  background: rgb(138, 135, 135);
}
/* Responsive */
@media screen and (max-width: 850px) and (min-width: 501px) {
  .image{ 
    height: 100vh;
  }
  .main-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 8%;
  }
  .about-descrip{
    padding: 3rem 1.5rem 0 1.5rem;
  }
}
@media screen and (max-width: 500px) {
  .image{ 
    height: 130vh;
  }
  .main-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 5%;
  }
  .job{
    padding-bottom: 3rem;
  }
  .projects{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .content{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .card-img-top{
    height: 100px;
    width: 125px;
    padding-bottom: 1rem;
  }
  .card-text, .card {
    max-width: 300px;
    font-size: 0.9rem;
    margin: 0
  }
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .icons {
    margin-top: 1rem;
  }
  .projects {
    padding: 0;
    margin: 4rem 0;
  }
  .skills {
    padding-top: 3rem;
    font-size: 1rem;
  }
}